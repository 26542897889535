import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Login";
import Home from "@/views/Home";

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login'},
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(function (to, from, next){
  if(to.name === 'home') {
    if(!localStorage.getItem('token')) {
      router.push({name: 'login'})
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
