import Notice from "@/views/notice/Notice";
import Welcome from "@/views/welcome/Welcome";
import News from "@/views/news/News";
import SimpleLogo from "@/views/simpleLogo/SimpleLogo";
import Link from "@/views/link/Link";

const getters = {
    getRenderedScreen: state => {
        return state.renderedScreen
    },

    getRenderedScreenAnimation: state => {
        return state.renderedScreenAnimation
    },

    getCurrentViewTypeComponent: state => {
        switch (state.currentViewType) {
            case 1:
                return Welcome
            case 2:
                return News
            case 3:
                return Notice
            case 4:
                return Link
            case 0:
                return SimpleLogo
        }
    },

    getCurrentViewId: state => {
        return state.currentViewId
    },

    getCurrentViewType: state => {
        return state.currentViewType
    },

    getCurrentViewTypeTimestamp: state => {
        return state.currentViewTypeTimestamp
    },

    getCurrentViewContent: state => {
        return state.currentViewContent
    },

    getCustomerIdentifier: state => {
        return state.customerIdentifier
    },

    getCustomerColor: state => {
        return state.customerColour[state.customerIdentifier]
    },
    getCustomerId:state => {
        return state.customerId
    },
    getResourcePath:state => {
        return state.resourcePath
    }
 }

export default getters
