const mutations = {
    login(state, data) {
        state.token = data.token

        if(data.initialView !== null) {
            state.currentViewContent = JSON.parse(data.initialView.content)
            state.currentViewType = data.initialView.view_type_id
            state.currentViewTypeTimestamp = data.initialView.updated_at
        } else {
            state.currentViewType = 0
        }
        state.customerIdentifier = data.id.toLowerCase()
        state.customerId = data.customer_id
        localStorage.setItem('logoid', data.id)
        localStorage.setItem('customer_id', data.customer_id)
        localStorage.setItem('deviceId', data.deviceId)
        localStorage.setItem('token', data.token)
    },

    logout(state) {
        state.token = null
        localStorage.removeItem('token')
    },

    setView(state, data) {
        state.renderedScreenAnimation = false

        setTimeout(() => {
            if(data.view !== null) {
                state.currentViewContent = JSON.parse(data.view.content)
                state.currentViewType = data.view.view_type_id
                state.currentViewId = data.view.id
                state.currentViewTypeTimestamp = data.view.updated_at
                state.renderedScreenAnimation = true
            } else {
                state.currentViewContent = null
                state.currentViewType = 0
                state.currentViewId = null
                state.currentViewTypeTimestamp = null
                state.renderedScreenAnimation = true
            }
        }, 500)
    }
}

export default mutations
