import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from "axios";
import VueAxios from "vue-axios";
import router from './router'
import VueRouter from 'vue-router'
import './registerServiceWorker'
import vueHeadful from 'vue-headful'
import { api } from './config/api'
import store from './store'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.component('vue-headful', vueHeadful)


axios.defaults.baseURL = api.url + api.path.default
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');

  if(token !== null) {
    const bearerToken = localStorage.getItem('token');
    const bearer = "Bearer " + bearerToken;
    config.headers.Authorization = bearer;
  }

  return config
})


axios.interceptors.response.use(
    response => response,
    function(error) {
      if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
        if(window.nsWebViewBridge !== undefined) {
          window.nsWebViewBridge.emit("logout", {logoutForced: true});
        }
        store.commit('logout')
        router.push({path: '/login'})
      }
    })

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
