import {api} from "@/config/api";

const state = {
    token: null,

    currentViewId: null,
    currentViewType: null,
    currentViewTypeTimestamp: null,
    currentViewContent: null,

    renderedScreenAnimation: true,

    customerIdentifier: localStorage.getItem('logoid'),
    customerId: localStorage.getItem('customer_id'),
    resourcePath: api.url+api.path.storage+'files/',

    customerColour: {
        'sparkasse': '#E6051A',
        'steinbach': '#2A85C9'
    }
}

export default state
