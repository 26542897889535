<template>
  <div class="news-grid">
    <div class="news-main-content">
      <div class="news-info">
        <div class="news-header" ref="news-header">
          <span :style="{fontSize: headerFontSize + 'px', lineHeight: headerLineHeight+'em'}">{{ getCurrentViewContent.header }}</span>
        </div>
        <div class="news-date">
          <span :style="{fontSize: dateFontSize + 'px', lineHeight: '1.25em'}">{{ new Date(getCurrentViewContent.date).toLocaleDateString("de-DE", {day: "2-digit", month: "long", year: "numeric"}) }}</span>
        </div>
      </div>
      <div class="news-text">
        <div class="news-text-wrapper" ref="news-text" :style="{height: pageHeight+'px'}">
          <!--<div v-show="slide" class="news-text-padder"/>-->
          <div
              class="news-text-content"
              ref="news-text-content"
              :style="{fontSize: textFontSize + 'px', lineHeight: textLineHeight + 'px', paddingBottom: calculatedTotalPadding+'px'}"
          >
            <p>
              <span v-html="getCurrentViewContent.text"></span>
            </p>

          </div>
        </div>

        <div class="news-page-indicator" :style="{fontSize: pagerFontSize + 'px', lineHeight: '1em'}">
          Seite {{ currentPage }} von {{ totalPages }}
        </div>

      </div>
    </div>
    <div class="news-gallery">
      <NewsGallery/>
    </div>
    <div class="news-logo">
      <img :src="getResourcePath+getCustomerId+'/logo/logo.svg'"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NewsGallery from "@/views/news/NewsGallery";
export default {
  components: {NewsGallery},
  props: ['customColor'],
  name: "News",
  created() {
    this.pageHeight = Math.ceil(window.innerHeight * 0.311)
  },
  mounted() {
    let textToHeaderFactor = this.headerFontSize / this.textFontSize
    let dateToHeaderFactor = this.headerFontSize / this.dateFontSize
    let textToPagerFactor = this.textFontSize / this.pagerFontSize
    let newsText = Math.round(this.$refs['news-text'].getBoundingClientRect().height * 100) / 100
    let newsHeader = this.$refs['news-header'].clientHeight

    //get Pages

    this.$nextTick(() => {
      let textFontSize = (newsText / 9) / this.textLineHeight
      this.pageHeight = textFontSize * this.textLineHeight * 9
      this.textFontSize = textFontSize
      this.textLineHeight = textFontSize * 1.8

      this.headerFontSize = this.textFontSize * textToHeaderFactor
      let headerFontSize = (newsHeader / 2) / this.headerLineHeight
      if(headerFontSize < this.headerFontSize) {
        let headerLineHeight = (newsHeader / 2) / this.headerFontSize
        this.headerLineHeight = headerLineHeight
      }

      this.dateFontSize = this.headerFontSize / dateToHeaderFactor
      this.pagerFontSize = this.textFontSize / textToPagerFactor
    })

    setTimeout(() => {
      let newsScrollHeight = this.$refs['news-text-content'].scrollHeight
      this.totalHeight = newsScrollHeight
      this.totalPages = Math.ceil(newsScrollHeight / this.pageHeight)
      this.autoScroll()
    }, 1)
  },
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId'
    }),
    calculatedTotalPadding() {
        return (this.totalPages * this.pageHeight) - this.totalHeight
    }
  },
  destroyed() {
    clearInterval(this.slideIntervalId)
  },
  methods: {
    autoScroll() {
      this.slideIntervalId = setInterval(() => {
        if(this.currentPage < this.totalPages) {
          let calculatedScroll = Math.round(this.pageHeight * 0.975 + (this.calculatedTotalPadding / this.totalPages) / 10)
          this.$refs['news-text'].scrollTo({
            top: calculatedScroll * this.currentPage,
            behavior: 'smooth'
          })
          this.currentPage += 1
        } else if(this.currentPage === this.totalPages) {
          this.slide = true
          //this.slide = false
          this.$refs['news-text'].scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          this.currentPage = 1
        }
      }, 10000)
    }
  },
  data() {
    return {
      headerSize: 30,
      dateSize: 20,
      textSize: 14,
      pageSize: 12,

      textFontSize: 14,
      textLineHeight: 1.8,
      pagerFontSize: 12,

      headerFontSize: 30,
      headerLineHeight: 1.1,

      dateFontSize: 20,

      currentPage: 1,
      totalPages: 0,
      totalHeight: 0,
      pageHeight: 0,

      calculatedPageHeight: 0,

      opacityHelper: 1,
      marginTopHelper: 0,
      slide: false,
      slideIntervalId: null,

      header: 'Platz für eine Überschrift',
      date: new Date(),
      text: "Weit hinten, hinter den Wortbergen, fern der Länder Vokalien und Konsonantien leben die Blindtexte. Abgeschieden wohnen sie in Buchstabhausen an der Küste des Semantik, eines großen Sprachozeans. Ein kleines Bächlein namens Duden fließt durch ihren Ort und versorgt sie mit den nötigen Regelialien. Es ist ein paradiesmatisches Land, in dem einem gebratene Satzteile in den Mund fliegen. Nicht einmal von der allmächtigen Interpunktion werden die Blindtexte beherrscht – ein geradezu unorthographisches Leben. Eines Tages aber beschloß eine kleine Zeile Blindtext, ihr Name war Lorem Ipsum, hinaus zu gehen in die weite Grammatik…" +
          "1 hier geht es weiter 2 hier geht es weiter 3 hier geht es weiter 4 hier geht es weiter 5 hier geht es weiter 6 hier geht es weiter 7 hier geht es weiter 8 hier geht es weiter9 hier geht es weiter"
    }
  }
}
</script>

<style>

</style>
