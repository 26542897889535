import axios from "axios";

const actions = {
    login({commit}, data) {
        return new Promise(resolve => {
            axios.post('view-auth/login', data).then((res) => {
                const params = {
                    token: res.data.token,
                    deviceId: data.activationId,
                    initialView: res.data.initialView,
                    id: res.data.id,
                    customer_id: res.data.customer_id
                }
                commit('login', params)
                resolve('home')
            })
        })
    },

    logout({commit}) {
        return new Promise(resolve => {
            axios.get('view-auth/logout').then(res => {
                resolve(res)
                commit('logout')
            })
        })
    },

    fetchScreenUpdate({commit, getters}) {
        let params = {
            currentView: getters.getCurrentViewId,
            updateAtTimestamp: getters.getCurrentViewTypeTimestamp
        }

        axios.post('views/views', params).then((res) => {
            if(res !== undefined) {
                if(res.data.view !== false) {
                    commit('setView', res.data)
                }
            }
        })
    }
}

export default actions
