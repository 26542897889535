<template>
  <div class="simple-logo-view">
    <img
        :src="getResourcePath+getCustomerId+'/logo/logo.svg'"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "SimpleLogo",
  computed: {
    ...mapGetters({
      getCustomerIdentifier: 'getCustomerIdentifier',
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId'
    }),
  }
}
</script>

<style scoped>

</style>
