<template>
  <div class="notice-grid">
    <div class="notice-header">
      <span :style="{fontSize: headerSize + 'px', lineHeight: '1em'}">{{ getCurrentViewContent.header }}</span>
    </div>
    <div class="notice-room">
      <span :style="{fontSize: roomSize + 'px', lineHeight: '1.25em'}">{{ getCurrentViewContent.room }}</span>
    </div>
    <div class="notice-text">
      <span :style="{fontSize: textSize + 'px', lineHeight: '1.25em'}">{{ getCurrentViewContent.text }}</span>
    </div>
    <div class="notice-slot">
      <div class="notice-direction">
        <v-icon :style="{transform: 'rotate('+getCurrentViewContent.direction * 90+'deg)', color: getCustomerColor}">
          mdi-arrow-up
        </v-icon>
      </div>
    </div>
    <div class="notice-logo">
      <!--<img :src="require('src/customers/'+getCustomerIdentifier.toLowerCase()+'/assets/logo.svg')"/>-->
      <img :src="getResourcePath+getCustomerId+'/logo/logo.svg'"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "Notice",
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getCustomerColor: 'getCustomerColor',
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId'
    }),
  },
  data() {
    return {
      headerSize: 70,
      roomSize: 30,
      textSize: 20,

      header: "Wegweiser zu einem Vortrag",
      room: "Raum 2.35",
      text: 'Weit hinten, hinter den Wortbergen, fern der Länder Vokalien und Konsonantien leben die Blindtexte. Abgeschieden wohnen sie in Buchstabhausen an der Küste des Semantik, eines großen Sprachozeans.'
    }
  },
  mounted() {
    let arrow = document.getElementsByClassName('mdi-arrow-up')
    arrow[0].style.color = this.getCustomerColor
  }
}
</script>

<style>

</style>
