<template>
  <div v-if="getCurrentViewType !== null" class="components-wrapper" :style="{borderTopColor: getCustomerColor}">
<!--
    <v-fade-transition mode="out-in">
      <div v-show="getRenderedScreenAnimation">
        {{ getCurrentViewContent.headline }}
      </div>
    </v-fade-transition>
-->
    <v-fade-transition mode="out-in">
      <div v-show="getRenderedScreenAnimation" class="comp-wrapper">
        <Component :is="getCurrentViewTypeComponent" :customColor="getCustomerColor"/>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Home',
  components: {

  },

  data() {
    return {
      screenFetcher: null,
    }
  },

  methods: {
    ...mapActions({
      fetchScreenUpdate: 'fetchScreenUpdate'
    })
  },

  computed: {
    ...mapGetters({
      getRenderedScreen: 'getRenderedScreen',
      getRenderedScreenAnimation: 'getRenderedScreenAnimation',
      getCurrentViewContent: 'getCurrentViewContent',
      getCurrentViewTypeTimestamp: 'getCurrentViewTypeTimestamp',
      getCurrentViewTypeComponent: 'getCurrentViewTypeComponent',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getCurrentViewType: 'getCurrentViewType',
      getCustomerColor: 'getCustomerColor'
    }),
  },

  created() {
    this.screenFetcher = setInterval(() => {
      this.fetchScreenUpdate()
    }, 60000)
  },

  beforeDestroy() {
    clearInterval(this.screenFetcher)
  }
}
</script>

<style>

</style>
