<template>
  <div class="login-wrapper">
    <div class="top-bg">
    </div>

    <div class="logo">
      <img :src="require('../projects/' + project + '/static/img/logo/logo.svg')"/>
    </div>

    <div class="bottom-bg">
      <div class="form-wrapper">
        <div class="form-root">
          <div class="form-frame">
            <template>
              <v-form v-on:submit.prevent>
                <v-text-field id="activation-code"
                              label="Gerätecode"
                              autofocus
                              v-model="activation_id"/>

                <v-btn type="submit" @click="attemptLogin" id="login">
                  Login
                </v-btn>
              </v-form>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
const MainSettings = require('./../../main.config.js')
export default {
  name: "Login",

  data() {
    return {
      activation_id: ' ',
      project: MainSettings.activeProject
    }
  },

  methods: {

    ...mapActions({
      login: 'login'
    }),

    attemptLogin() {
      const params = {
        activationId: this.activation_id
      }

      this.login(params).then((redirect) => {
        this.$router.push({name: redirect})
        if(window.nsWebViewBridge !== undefined) {
          window.nsWebViewBridge.emit("loginSuccessful", { loginConfirmed: true });
        }
      })
    }
  },

  mounted() {
    this.activation_id = localStorage.getItem('activationId')
  }
}
</script>

<style>

</style>
