<template>
<div class="welcome-grid">
  <div class="welcome-header" ref="welcome-header">
    <span :style="{fontSize: headerFontSize + 'px', lineHeight: headerLineHeight + 'em'}">{{ header }}</span>
  </div>
  <div class="welcome-subheader" ref="welcome-subheader">
    <span :style="{fontSize: subheaderFontSize + 'px', lineHeight: subheaderLineHeight + 'em'}">{{ getCurrentViewContent.view_name }}</span>
  </div>
  <div class="welcome-time" ref="welcome-time">
    <span :style="{fontSize: timeFontSize + 'px', lineHeight: timeLineHeight + 'em'}">{{ getCurrentViewContent.period_start }} - {{ getCurrentViewContent.period_end }} Uhr</span>
  </div>
  <div class="welcome-persons" ref="welcome-persons">
    <span :style="{fontSize: personFontSize + 'px', lineHeight: personLineHeight + 'em'}">{{ getCurrentViewContent.persons }}</span>
  </div>
  <div class="welcome-logo">
    <img :src="getResourcePath+getCustomerId+'/logo/logo.svg'"/>
  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "Welcome",
  mounted() {
    //let welcomeHeader = this.$refs['welcome-header'].clientHeight
  },
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
      getCustomerIdentifier: 'getCustomerIdentifier',
      getCustomerId: 'getCustomerId',
      getResourcePath: 'getResourcePath'
    }),
  },
  data() {
    return {
      header: 'Herzlich Willkommen',
      subheader: "Gastfirma",
      time: "10:00 - 11:30 Uhr",
      persons: "Max Mustermann, Max Mustermann, Max Mustermann," +
          "Max Mustermann, Max Mustermann, Max Mustermann",

      headerSize: 90,
      subheaderSize: 56,
      timeSize: 40,
      personSize: 28,

      headerFontSize: 90,
      headerLineHeight: 1,
      subheaderFontSize: 56,
      subheaderLineHeight: 1.25,
      timeFontSize: 40,
      timeLineHeight: 1.25,
      personFontSize: 28,
      personLineHeight: 1.25
    }
  }
}
</script>

<style>

</style>
