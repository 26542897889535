<template>
  <div class="link-grid">
    <iframe :src="getCurrentViewContent.url"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Link",
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
    })
  }
}
</script>

<style scoped>

</style>
