<template>
  <div>
    <v-img :src="getResourcePath+getCustomerId+'/images/pic0.jpg'"/>
    <v-img :src="getResourcePath+getCustomerId+'/images/pic1.jpg'"/>
    <v-img :src="getResourcePath+getCustomerId+'/images/pic2.jpg'"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NewsGallery",
  computed: {
    ...mapGetters({
      getResourcePath: 'getResourcePath',
      getCustomerId: 'getCustomerId'
    }),
  }
}
</script>

<style scoped>

</style>
